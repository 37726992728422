import { createBrowserRouter } from 'react-router-dom';
import App from '../App.jsx';
import MainPage from '../pages/MainPage/MainPage.jsx';
import Login from '../pages/Login/Login.jsx';
import Register from '../pages/Register/Register.jsx';
import MainPageRestyle from '../pages/MainPage/MainPageRestyle.jsx';
import UserList from '../pages/UserList/UserList.jsx';
import UserView from '../pages/UserView/UserView.jsx';
import { default as RbpApp } from '../rbp_src/App.jsx';
import { default as RbpMainPageRestyle } from '../rbp_src/pages/MainPage/MainPageRestyle.jsx';
import { default as RbpUserList } from '../rbp_src/pages/UserList/UserList.jsx';
import { default as RbpUserView } from '../rbp_src/pages/UserView/UserView.jsx';
import Landing from '../landing_src/pages/Landing/Landing.jsx';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      // { path: '/main-page', element: <MainPage /> },
      { path: 'root', element: <MainPageRestyle /> },
      { path: 'user-list', element: <UserList /> },
      { path: 'user/:id', element: <UserView /> },
      // { path: '/user', element: <div>TEST</div> },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/rbp',
    element: <RbpApp />,
    children: [
      { path: 'root', element: <RbpMainPageRestyle /> },
      { path: 'user-list', element: <RbpUserList /> },
      { path: 'user/:id', element: <RbpUserView /> },
    ],
  },
  {
    path: '/landing',
    element: <Landing />,
  },
]);

export default routes;
